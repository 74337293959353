let body = document.querySelector('body');


let burger = document.querySelector('.js-burger');
let menu = document.querySelector('.js-menu__list');
let closeBtn = document.querySelector('.js-close-btn');
burger.onclick = function() {
	body.classList.toggle('is-overflow');
	menu.classList.toggle('is-active');
	burger.classList.toggle('is-active');
}

let lang = document.querySelector('.js-lang__title');
let langList = document.querySelector('.js-lang__list');
lang.onclick = function() {
	langList.classList.toggle('is-active');
}
